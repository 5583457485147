import { NgModule } from '@angular/core';
import { NgxEchartsDirective } from './ngx-echarts.directive';

@NgModule({
  imports: [],
  declarations: [
    NgxEchartsDirective
  ],
  exports: [
    NgxEchartsDirective
  ]
})
export class NgxEchartsModule { }
