import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NgxCaptchaService {

  captchSource = new BehaviorSubject(null);
  captchStatus = this.captchSource.asObservable();
  constructor() { }

  setCaptchaStatus(code: any): void {
    this.captchSource.next(code);
  }

  generateCaptchaCode(textType: string = 'AN', length: number = 6, textCase: string = 'U'): any {
    const codeObj = {
      N: '1234567890',
      C: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    };
    let codeStr = '';
    switch (textType) {
      case 'AN':
        codeStr = codeObj.N;
        switch (textCase) {
          case 'U':
            codeStr += codeObj.C;
            break;
          case 'L':
            codeStr += codeObj.C.toLowerCase();
            break;
          case 'M':
            codeStr += codeObj.C.slice(0, 13) + codeObj.C.slice(3, 26).toLowerCase();
            break;
        }
        break;
      case 'N':
        codeStr = codeObj.N;
        break;
      case 'C':
        codeStr = codeObj.C;
        break;
    }
    let code = '';
    for (let i = 0; i < length; i++) {
      code += codeStr.charAt(Math.floor(Math.random() * codeStr.length));
    }
    return code;
  }
}
