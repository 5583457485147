import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgxCaptchaComponent } from './ngx-captcha.component';
import {NgxCaptchaService} from './ngx-captcha.service';


@NgModule({
  declarations: [NgxCaptchaComponent],
  imports: [
    BrowserModule,
    FormsModule
  ],
  exports: [NgxCaptchaComponent]
})
export class NgxCaptchaModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: NgxCaptchaModule,
      providers: [ NgxCaptchaService ]
    };
  }
}
