import { Component, OnChanges, Input} from '@angular/core';
import { NgxCaptchaService } from './ngx-captcha.service';
@Component({
	selector: 'ngx-captcha',
	templateUrl: './ngx-captcha.component.html',
	styleUrls: ['./ngx-captcha.component.scss']
})
export class NgxCaptchaComponent implements OnChanges {

	@Input('config') config: any = {};
	captch_input: any = null;
	code: any = null;
	resultCode: any = null;
	constructor(private captchService: NgxCaptchaService) { }
	ngOnChanges() {
		if (this.config) {
      this.config.font.size = (this.config.font && this.config.font.size) ? this.config.font.size : '40px';
      this.config.font.family = (this.config.font && this.config.font.family) ? this.config.font.family : 'sans-serif';
      this.config.textType = (this.config.textType) ? this.config.textType : 'AN';
      this.config.length = (this.config.length) ? this.config.length : 6;
      this.config.cssClass = (this.config.cssClass) ? this.config.cssClass : '';
      this.config.type = (this.config.type) ? Number(this.config.type) : 1;
      this.config.back.stroke = (this.config.back && this.config.back.stroke) ? this.config.back.stroke : '';
      this.config.back.solid = (this.config.back && this.config.back.solid) ? this.config.back.solid : '#fbfbfb';
      this.config.textType = (this.config.textType) ? this.config.textType : 'AN';
      this.config.textCase = (this.config.textCase) ? this.config.textCase : 'U';
      this.createCaptcha();
		}
	}
	createCaptcha(): void {

  switch (this.config.type) {
    case 1:
      this.code = this.resultCode  = this.captchService.generateCaptchaCode(this.config.textType, this.config.length, this.config.textCase);
      break;
    case 2:
      const num1 = Math.floor(Math.random() * 99);
      const num2 = Math.floor(Math.random() * 9);
      const operators = ['+', '-'];
      const operator = operators[(Math.floor(Math.random() * operators.length))];
      this.code = num1 + operator + num2 + '=?';
      this.resultCode = (operator === '+') ? (num1 + num2) : (num1 - num2);
      break;
    case 3:
      break;
  }


  setTimeout(() => {
			const captcahCanvas: any = document.getElementById('captcahCanvas');
			const ctx = captcahCanvas.getContext('2d');
			ctx.fillStyle = this.config.back.solid;
			ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);

			// ctx.font = '10px Arial';
			// ctx.fillStyle = "#000000";
			// ctx.fillText("Powered By BinsSoft", captcahCanvas.width - 95, captcahCanvas.height - 5);

			ctx.beginPath();

			if (this.config.type !== 3) {
				captcahCanvas.style.letterSpacing = 15 + 'px';
				ctx.font = this.config.font.size + ' ' + this.config.font.family;
				ctx.fillStyle = this.config.font.color;
				ctx.textBaseline = 'middle';
    if (this.config.type == 1) {
      const yAxix = [50, 30, 60, 40];
      Array.from(this.code).forEach((c, i) => {
            const y =  yAxix[Math.floor(Math.random() * yAxix.length)];
            ctx.fillText(c, (i + 1) * 40, y);
      });
    } else {
      ctx.fillText(this.code, 40, 50);
    }

				if (this.config.back.stroke) {
					ctx.strokeStyle = this.config.back.stroke;
					for (let i = 0; i < 150; i++) {
						ctx.moveTo(Math.random() * 300, Math.random() * 300);
						ctx.lineTo(Math.random() * 300, Math.random() * 300);
					}
					ctx.stroke();
				}
			} else {
				ctx.fillStyle = '#000';
				const position = {
					x: 20,
					y: 20,
					width: 30,
					height: 30,
					thickness: 1
				};
				ctx.fillRect(position.x - (position.thickness), position.y - (position.thickness), position.width + (position.thickness * 2), position.height + (position.thickness * 2));
				ctx.fillStyle = '#FFF';
				ctx.fillRect(position.x, position.y, position.width, position.height);
				ctx.font = '15px ' + this.config.font.family;
				ctx.fillStyle = this.config.font.color;
				ctx.fillText('I\'m not a robot', (position.x) + position.width + 5, (position.y * 2));
				captcahCanvas.addEventListener('mousedown', (e: any) => {
					const clickPosition = this.getMousePosition(captcahCanvas, e);
					const midOfBox = {
						x: position.x + position.width / 2,
						y: position.y + position.height / 2
					};
					this.resultCode = 1;
					if (Math.abs(clickPosition.x - midOfBox.x) <= 5 &&
						Math.abs(clickPosition.y - midOfBox.y) <= 5) { /// it is a robot
						this.captch_input = 0;
					} else { // it is a human
						if (
							(clickPosition.x >= position.x && clickPosition.x <= (position.x + position.width)) &&
							(clickPosition.y >= position.y && clickPosition.y <= (position.y + position.height))
						) {
							this.captch_input = 1;
							ctx.font = '30px ' + this.config.font.family;
							ctx.fillText('✔', position.x, position.y + position.height - 5);
						}
					}
					this.checkCaptcha();
				});

			}


		}, 100);
	}

  playCaptcha(): void {
    const msg = new SpeechSynthesisUtterance(this.code.split('').join(' '));
    msg.pitch = 1;
    window.speechSynthesis.speak(msg);
  }

  checkCaptcha(): void {
    if (this.captch_input !== this.resultCode) {
      this.captchService.setCaptchaStatus(false);
    } else {
      this.captchService.setCaptchaStatus(true);
    }
  }

  getMousePosition(canvas: any, event: any): any {
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return {
      x,
      y
    };
  }
}
